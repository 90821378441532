import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { AppStateContext, AuthContext, PriceBookContext } from '../../Context';
import bindMultiple from '../../common/helpers/bindMultiple';
import QuoteNumber from '../QuoteNumber';
import QuoteStatus from '../QuoteStatus';
import SectionsNavigation from './nested/SectionsNavigation';
import './css/header-information.css';
import { ORDER_TYPES_TRANSLATE, PRICEBOOK_TAGS } from '../../common/enums';
import IconButtonWithTooltip from '../IconButtonWithTooltip';

class HeaderInformation extends PureComponent {
  static propTypes = {
    quoteId: PropTypes.number,
    quote: PropTypes.object,
    route: PropTypes.object,
    router: PropTypes.object,
    appState: PropTypes.object,
    onQuoteHistoryClick: PropTypes.func,
    toggleAutoCalculation: PropTypes.func,
    onCheckpointClick: PropTypes.func,
    checkpointsDisabled: PropTypes.bool,
    orderType: PropTypes.string,
    onMarkersClick: PropTypes.func,
    markersBtnDisabled: PropTypes.bool,
    markersTooltipMessage: PropTypes.string,
  };

  constructor(props) {
    super(props);

    bindMultiple(
      this,
      this.handleCheckpoints,
      this.handleAutoCalculateToggle,
      this.handleQuoteHistory,
      this.handleMarkers
    );
  }

  render() {
    const {
      quoteId,
      /** @type {CustomerOrderModel} */
      quote,
      route,
      router,
      appState,
      intl,
      onCollapseExpandClick,
      isAllCollapsed,
      sectionsList,
      navCallback,
      navGeneralSectionCallback,
      checkpointsDisabled,
      markersBtnDisabled,
      markersTooltipMessage,
    } = this.props;

    const historyBtnDisabled = quoteId === null;
    const disabledClassName = ' no-border secondary';
    const historyBtnProps = {
      disabled: historyBtnDisabled,
      title: intl.formatMessage({ id: 'msg_history' }),
      onClick: this.handleQuoteHistory,
      className: 'square history-btn no-bg' + (historyBtnDisabled ? disabledClassName : ''),
      type: 'button',
    };
    const checkpointsBtnProps = {
      disabled: checkpointsDisabled,
      title: intl.formatMessage({ id: 'msg_checkpoints' }),
      onClick: this.handleCheckpoints,
      className: 'square checkpoints-btn no-bg' + (checkpointsDisabled ? disabledClassName : ''),
      type: 'button',
    };
    const collapseExpandBtnProps = {
      disabled: appState.quoteLoading,
      title: isAllCollapsed
        ? intl.formatMessage({ id: 'msg_expand_all' })
        : intl.formatMessage({ id: 'msg_collapse_all' }),
      onClick: onCollapseExpandClick,
      className: 'square collapse-expand-btn no-bg' + (appState.quoteLoading ? disabledClassName : ''),
      type: 'button',
    };
    const markersBtnProps = {
      disabled: markersBtnDisabled,
      title: intl.formatMessage({ id: 'msg_marker_overrides' }),
      onClick: this.handleMarkers,
      className: 'square markers-btn no-bg' + (markersBtnDisabled ? disabledClassName : ''),
      iconClass: 'icon-marker',
      showTooltip: markersBtnDisabled,
      tooltipText: markersTooltipMessage,
    };
    const focusModeButtonProps = {
      id: 'focus-mode-toggle',
      title: intl.formatMessage({
        id: quote.isFocusMode
          ? 'msg_focus_mode_empty_categories_hidden_tooltip'
          : 'msg_focus_mode_empty_categories_visible_tooltip',
      }),
      onClick: quote.toggleFocusMode,
      className: classnames({
        square: true,
        'no-bg': true,
        // TA specific CSS classes. To make state determination easier
        'focus-mode-enabled': quote.isFocusMode,
        'focus-mode-disabled': !quote.isFocusMode,
      }),
      type: 'button',
    };
    const autoCalcButtonProps = {
      id: 'auto-calculate-toggle',
      title: intl.formatMessage({
        id: AppStateContext.model.isAutoCalculateEnabled
          ? 'msg_auto_calc_is_on_tooltip'
          : 'msg_auto_calc_is_off_tooltip',
      }),
      onClick: this.handleAutoCalculateToggle,
      className: classnames({
        square: true,
        'no-bg': true,
        // TA specific CSS classes. To make state determination easier
        'auto-calc-enabled': AppStateContext.model.isAutoCalculateEnabled,
        'auto-calc-disabled': !AppStateContext.model.isAutoCalculateEnabled,
      }),
      type: 'button',
    };

    const orderTypeStr = ORDER_TYPES_TRANSLATE[quote.orderType]
      ? intl.formatMessage({ id: ORDER_TYPES_TRANSLATE[quote.orderType] })
      : '';

    return (
      <Fragment>
        <div className="header-controls">
          {sectionsList && (
            <React.Fragment>
              <SectionsNavigation
                sectionsList={sectionsList}
                onSelectCallback={navCallback}
                onSelectGeneralSectionItemCallback={navGeneralSectionCallback}
                isSpiffVisible={quote.spiffDiscount.isSpiffVisible}
                isRental={quote.isRental}
                isPurchase={quote.isPurchase}
                isLeaseOptionAvailable={quote.isLeaseOptionAvailable}
              />
              <div className="collapse-expand-all-wrapper">
                <button {...collapseExpandBtnProps}>
                  <span
                    className={classnames({
                      'icon-expand-less': !isAllCollapsed,
                      'icon-expand-more': isAllCollapsed,
                    })}
                  />
                </button>
              </div>
            </React.Fragment>
          )}
          <button {...focusModeButtonProps}>
            <span className={quote.isFocusMode ? 'icon-visibility-outlined' : 'icon-visibility'} />
          </button>
          {AuthContext.model.hasInternalPermissions &&
            !PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_COMMUNITY) && (
              <div className="checkpoints-btn">
                <button {...checkpointsBtnProps}>
                  <span className="icon-flag" />
                </button>
              </div>
            )}
          {AuthContext.model.isSalesOps && !PriceBookContext.model.isSupportsSwitchvoxSIPStation && (
            <div className="markers-btn">
              <IconButtonWithTooltip {...markersBtnProps} />
            </div>
          )}
          {AuthContext.model.hasInternalPermissions && (
            <React.Fragment>
              {!PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_COMMUNITY) && (
                <div className="quote-history-wrapper">
                  <button {...historyBtnProps}>
                    <span className="icon-history" />
                  </button>
                </div>
              )}
              <button {...autoCalcButtonProps}>
                <span
                  className={AppStateContext.model.isAutoCalculateEnabled ? 'icon-calculate' : 'icon-calculate-outlined'}
                />
              </button>
            </React.Fragment>
          )}
        </div>
        <div className="header-center-block">
          <div className="header-quote-info">
            <QuoteNumber quoteId={quoteId} customerName={quote.customerName} orderType={orderTypeStr} />
            <QuoteStatus route={route} router={router} appState={appState} quote={quote} />
          </div>
        </div>
      </Fragment>
    );
  }

  handleAutoCalculateToggle() {
    if (typeof this.props.toggleAutoCalculation === 'function') {
      this.props.toggleAutoCalculation();
    }
  }

  handleQuoteHistory() {
    if (typeof this.props.onQuoteHistoryClick === 'function') {
      this.props.onQuoteHistoryClick();
    }
  }

  handleCheckpoints() {
    if (typeof this.props.onCheckpointClick === 'function') {
      this.props.onCheckpointClick();
    }
  }

  handleMarkers() {
    if (typeof this.props.onMarkersClick === 'function') {
      this.props.onMarkersClick();
    }
  }
}

export default injectIntl(HeaderInformation);
