export const QUOTE_SERVICE_TYPE = {
  businessVoicePlatform: 'BUSINESS_VOICE_PLATFORM',
  sangomaCXStandalone: 'SANGOMA_CX_STANDALONE',
  standaloneServiceNonUCaaS: 'STANDALONE_SERVICE_NON_UCAAS',
  swvxSipstation: 'SWVX_SIPSTATION',
  community: 'COMMUNITY',
};

export const QUOTE_SERVICE_TYPE_LABEL = {
  [QUOTE_SERVICE_TYPE.businessVoicePlatform]: 'msg_business_voice_platform',
  [QUOTE_SERVICE_TYPE.sangomaCXStandalone]: 'msg_sangoma_cx_standalone',
  [QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS]: 'msg_standalone_service_non_ucaas',
  [QUOTE_SERVICE_TYPE.swvxSipstation]: 'msg_switchvox_sip_station',
  [QUOTE_SERVICE_TYPE.community]: 'msg_community',
};

export const QUOTE_SERVICE_TYPE_OPTIONS = [
  {
    key: '1',
    value: QUOTE_SERVICE_TYPE.businessVoicePlatform,
    text: QUOTE_SERVICE_TYPE_LABEL[QUOTE_SERVICE_TYPE.businessVoicePlatform],
  },
  {
    key: '2',
    value: QUOTE_SERVICE_TYPE.sangomaCXStandalone,
    text: QUOTE_SERVICE_TYPE_LABEL[QUOTE_SERVICE_TYPE.sangomaCXStandalone],
  },
  {
    key: '3',
    value: QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS,
    text: QUOTE_SERVICE_TYPE_LABEL[QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS],
  },
  {
    key: '4',
    value: QUOTE_SERVICE_TYPE.swvxSipstation,
    text: QUOTE_SERVICE_TYPE_LABEL[QUOTE_SERVICE_TYPE.swvxSipstation],
  },
  {
    key: '5',
    value: QUOTE_SERVICE_TYPE.community,
    text: QUOTE_SERVICE_TYPE_LABEL[QUOTE_SERVICE_TYPE.community],
  },
];
