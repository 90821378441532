import CommonSectionModel from './CommonSectionModel';
import bindMultiple from '../../common/helpers/bindMultiple';
import { AuthContextModel, PriceBookContextModel } from '../../Context';
import { ORDER_TYPES, PRICEBOOK_TAGS, QUOTE_STATUS } from '../../common/enums';
import ERROR_TEXT_COLLECTION from '../../common/ERROR_TEXT_COLLECTION';
import { Validate } from '../index';
import _ from 'lodash';

class CustomerInformationSectionModel extends CommonSectionModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    //TODO use ConstantsModel props when they will be refactored to static props
    this.id = 'customer-information-section';
    this.title = 'msg_customer_information';
    this.isGeneralSection = true;

    bindMultiple(
      this,
      this.handleDbaNameChange,
      this.handleDbaToggleChange,
      this.handleTaxExemptChange,
      this.handleOvernightGuaranteeChange,
      this.handleExistingAlsAgreementChange,
      this.handleContactCenterConcurrencyChange,
      this.handleCustomerNameCommit,
      this.handleCustomerNameStateChange
    );

    this._ignoreOnExport.push('appErrors');
    this.__validateDba = _.debounce(this.__validateDba.bind(this), 500, { trailing: true });
  }

  __activeAddendumExists() {
    return this.customerOrder.quoteAddendums.some(a => a.active);
  }

  __validateDba() {
    Validate.requestCustomerNameValidation(this.customerOrder, true).then(({ code }) => {
      this.appErrors.dbaError = code || Validate.isDbaUnique(this.customerOrder).code;
      this.modelChanged(this);
    });
  }

  __onChangeDba(name, value) {
    this.customerOrder[name] = value;
    this.modelChanged(this);

    if (this.customerOrder.dba && this.customerOrder.useDba) {
      this.__validateDba();
    } else {
      this.appErrors.dbaError = Validate.isDbaUnique(this.customerOrder).code;
      this.modelChanged(this);
    }
  }

  get lockedState() {
    return super.lockedState || this.appState.switchvoxWlwDisabled;
  }

  get appErrors() {
    return this.appState.errors;
  }

  get showContactCenterConcurrencyToggle() {
    return !PriceBookContextModel.isSupportsSangomaCx && !PriceBookContextModel.isSupportsSwitchvoxSIPStation;
  }

  get showProCustomerToggle() {
    return (
      AuthContextModel.hasInternalPermissions &&
      !PriceBookContextModel.isSupportsSwitchvoxSIPStation &&
      !PriceBookContextModel.isSupportsSangomaCx
    );
  }

  get showOrderTypeFields() {
    return (
      [ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.ADD_ON, ORDER_TYPES.REWRITE].includes(this.customerOrder.orderType) &&
      !PriceBookContextModel.isSupportsSwitchvoxSIPStation
    );
  }

  get showOvernightToggle() {
    return !(
      (AuthContextModel.isPartner && !PriceBookContextModel.isWholeSale) ||
      PriceBookContextModel.isSupportsSwitchvoxSIPStation ||
      PriceBookContextModel.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_COMMUNITY)
    );
  }

  get alsDisabled() {
    return this.disabledState || (this.__activeAddendumExists() && AuthContextModel.isInternal);
  }

  get alsTooltip() {
    return this.__activeAddendumExists() && AuthContextModel.isInternal ? 'msg_disabled_als_tooltip' : '';
  }

  get showDbaFields() {
    return (
      this.customerOrder.orderType === ORDER_TYPES.NEW_CUSTOMER &&
      !PriceBookContextModel.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_COMMUNITY)
    );
  }

  get showTaxExemptField() {
    return (
      ![ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION].includes(this.customerOrder.orderType) &&
      !PriceBookContextModel.isWhiteLabelWholesale
    );
  }

  get showExistingAlsAgreementToggle() {
    return AuthContextModel.hasInternalPermissions && this.customerOrder.orderType !== ORDER_TYPES.REWRITE;
  }

  get showCreditDocumentsOnFileLabel() {
    return this.customerOrder.isAllInclusive && this.customerOrder.orderType === ORDER_TYPES.NEW_LOCATIONS;
  }

  get showGreatAmericaRentalAgreementOnFileLabel() {
    return this.customerOrder.isRental;
  }

  get greatAmericaRentalAgreementOnFileLabel() {
    return this.customerOrder.greatAmericaRentalAgreementOnFile ? 'msg_yes' : 'msg_no';
  }

  get creditDocumentsOnFileLabel() {
    return this.customerOrder.creditDocumentsOnFile ? 'msg_yes' : 'msg_no';
  }

  get customerNameError() {
    return ERROR_TEXT_COLLECTION[this.appErrors.customerNameLength || this.appErrors.customerNameIsUnique] || '';
  }

  get dbaError() {
    return ERROR_TEXT_COLLECTION[this.appErrors.dbaError] || '';
  }

  get disableDba() {
    return (
      (![QUOTE_STATUS.OPEN, QUOTE_STATUS.UNFINALIZED].includes(this.customerOrder.quoteStatus) ||
        !this.customerOrder.customerName) &&
      !this.customerOrder.salesOpsInAmending
    );
  }

  get customerNameEditable() {
    return ORDER_TYPES.NEW_CUSTOMER === this.customerOrder.orderType;
  }

  get customerNameLocked() {
    return (
      [QUOTE_STATUS.FINALIZED, QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.AMENDING].includes(
        this.customerOrder.quoteStatus
      ) && !this.customerOrder.salesOpsInAmending
    );
  }

  handleDbaNameChange(value) {
    this.__onChangeDba('dba', (value || '').substring(0, 65));
  }

  handleDbaToggleChange(value) {
    this.__onChangeDba('useDba', value);
  }

  handleTaxExemptChange(value) {
    this.customerOrder.onChange('taxExempt', value);
  }

  handleOvernightGuaranteeChange(value) {
    this.customerOrder.onChange('overnightGuarantee', value);
  }

  handleExistingAlsAgreementChange(value) {
    this.customerOrder.onChange('existingAlsAgreement', value);
  }

  handleContactCenterConcurrencyChange(value) {
    this.customerOrder.onChange('contactCenterConcurrency', value);
  }

  handleCustomerNameCommit(value) {
    this.customerOrder.onChange('customerName', value);
  }

  handleCustomerNameStateChange() {
    if (!this.appState.isCustomerNameValidationRequested) {
      this.appState.setState('isCustomerNameValidationRequested', true);
    }
  }
}

export default CustomerInformationSectionModel;
